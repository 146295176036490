
@import "~sweetalert2/dist/sweetalert2.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~element-plus/dist/index.css";
@import "~viewerjs/dist/viewer.css";
@import '~vue-cropper/dist/index.css';

@import "assets/fontawesome/css/all.css";
@import "assets/sass/zhi/theme.scss";
@import "assets/sass/zhi/common.scss";
@import "assets/sass/zhi/admin.scss";

body{
  margin: 0px;
  padding: 0px;
}
