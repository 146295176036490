
.zhi-image-upload{
  width: 120px;
  height: 120px;
  border: 1px solid #e1e1e1;
  position: relative;
  .upload-image{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .upload-btn{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    i{
      font-size: 32px;
      color: #a1a1a1;
    }
  }
  .upload-remove{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: flex-end;
    background-color: #000000;
    border-bottom-left-radius: 15px;
    z-index: 3;
    i{
      margin-right: 2px;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
