body{
  background-color: #f5f8fa;
  .page{
    >.aside{
      width: 240px;
      transition: width .3s ease;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 28px 0 rgba(82,63,105,.05);
      padding: 0;

      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 101;
      overflow: hidden;

      &.aside.aside-dark {
        background-color: #1e1e2d;
      }
      >.aside-logo{
        background-color: #1a1a27;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        padding: 0 25px;
        >.aside-toggle{
          color: #a1a5b7;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
    >.wrapper{
      transition: padding-left .3s ease;
      padding-left: 240px;
      padding-top: 60px;
      >.header{
        left: 240px;
        transition: left .3s ease;
        position: fixed;
        top: 0;
        right: 0;
        left: 240px;
        z-index: 100;
        background-color: #fff;
        height: 60px;
        padding: 0;
        box-shadow: 0 10px 30px 0 rgba(82,63,105,.05);
        display: flex;
        justify-content: space-between;
        >.header-content{
          padding: 0 20px;
          flex: 1;
          .left{

          }
          .right{
            .menu-item{
              height: 60px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
      >.footer{
        padding: 5px 0px;
        background-color: #ffffff;
        >.footer-content{
          padding: 0 20px;
          height: 35px;
        }
      }
      >.content{
        display: flex;
        .card {
          position: relative;
          display: flex;
          flex: 1;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;
          background-color: #fff;
          background-clip: border-box;
          border: 1px solid #eff2f5;
          border-radius: 0.475rem;
          .card-header {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            min-height: 50px;
            padding: 0 20px;
            background-color: transparent;
            border-bottom: 1px solid #eff2f5;
            .card-title {
              font-weight: 500;
              font-size: 16px;
              color: #181c32;
              display: flex;
              align-items: center;
              margin: 0.5rem;
              margin-left: 0;
            }
            .card-toolbar {
              display: flex;
              align-items: center;
              margin: 0.5rem 0;
              flex-wrap: wrap;
            }
          }
          .card-body{
            padding: 15px 20px;
          }
          .card-footer {
            background-color: transparent;
            border-top: 1px solid #eff2f5;
            padding: 10px 20px!important;
            border-radius: 0 0 calc(0.475rem - 1px) calc(0.475rem - 1px);
          }
        }
      }
    }
  }
  &.aside-minimize{
    .aside {
      width: 70px !important;
      transition: width 0.3s ease;
    }
    .wrapper {
      transition: padding-left 0.3s ease;
      padding-left: 70px !important;
    }
    .header {
      left: 70px !important;
      transition: left 0.3s ease;
    }
    .aside .aside-logo .logo-default {
      display: inline-block;
    }
    .aside .aside-logo .logo-minimize {
      display: none;
    }
    .aside:hover:not(.animating) {
      transition: width 0.3s ease;
      width: 70px;
      box-shadow: 5px 0px 10px rgba(70, 78, 95, 0.075);
    }
    .aside .aside-logo {
      justify-content: center;
    }
    .aside .aside-logo .logo {
      display: none;
    }
    .aside .aside-logo .logo-default {
      display: none;
    }
    .aside .aside-logo .logo-minimize {
      display: inline-block;
    }
    .aside-toggle i{
      color: #009EF7;
    }
    .rotate-180 {
      transform: rotateZ(180deg);
      transition: transform 0.3s ease;
    }
    .el-popper.is-light {
      border-width: 0px !important;
      .el-menu-item{
        height: 42px;
        font-size: 13px;
        .icon-level2{
          font-weight: bolder;
          font-size: 15px;
          margin-top: -6px;
        }
        &:hover{
          .icon-level2{
            color: #009EF7;
          }
          color: #ffffff;
        }
        .icon-level2{
          margin-right: 12px;
        }
      }
    }
  }
}