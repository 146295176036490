:root {
  --el-font-size-base: 13px !important;
  .el-checkbox {
    --el-checkbox-font-size: 13px !important;
  }
  --el-menu-sub-item-height: 42px !important;
}

body.swal2-height-auto {
  height: 100% !important;
}
a:hover {
  color: #006dab;
  text-decoration: none !important;
}

/* table section */
.table-header-blue th{
  background-color: #eff6fa !important;
  font-weight: 400 !important;
  color: #181C32;
}
.select-table .el-table__body .el-table__row{
  cursor: pointer;
}
.select-table .el-table__body tr.current-row>td.el-table__cell {
  background-color: #fff6bc;
}

.table-nohover .el-table__body tr:hover > td {
  background-color: #ffffff !important;
}

.zhi-input-append-0 .el-input-group__append{
  padding: 0px !important;
}
.zhi-input-append-10px .el-input-group__append{
  padding: 0px 10px !important;
}
.zhi-input-append-15px .el-input-group__append{
  padding: 0px 15px !important;
}

.el-dialog .el-dialog__header {
  margin-right: 0!important;
  border-bottom: 1px solid #ebedf3;
}

.el-form-col-info{
  padding: 0px 8px;
  font-size: 12px;
  display: inline-block;
  background-color: #eceefa !important;
  color: #434343;
}


.zhi-dialog{
  &.only-content{
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 0px !important;
      height: 100%;
      //line-height: 1px;
    }
  }
  &.body-padding-0{
    .el-dialog__body {
      padding: 0px !important;
    }
  }
}



/* common section */

.lh-20px {
  line-height: 20px !important;
}
.lh-24px {
  line-height: 24px !important;
}
.h-24px {
  height: 24px !important;
}
.h-30px {
  height: 30px !important;
}
.h-32px {
  height: 32px !important;
}
.h-320px {
  height: 320px !important;
}
.w-280px{
  width: 280px !important;
}
.w-360px{
  width: 360px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.px-15px {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-15px {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.zoom-85{
  zoom: 85%;
}
.zoom-90{
  zoom: 90%;
}

.fs-11px {
  font-size: 11px !important;
}
.fs-11p5px {
  font-size: 11.5px !important;
}
.fs-12px {
  font-size: 12px !important;
}
.fs-12p5px {
  font-size: 12.5px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-14px {
  font-size: 14px !important;
}

.flex-1{
  flex: 1;
}
.btn-flex{
  justify-content: center;
  align-content: center;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}


/** view container **/
.viewer-container {
  z-index: 8100 !important;
}


.zhi-input-btn-rounded{
  .el-input__wrapper{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;;
  }
  .el-button{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;;
  }
}

/** el overload **/
.el-radio-group{
  .el-radio{
    margin-right: 20px;
  }
}
.el-popper{
  .el-popconfirm__main{
    font-size: 12.5px;
  }
}

/** divider **/
.el-divider__text {
  background-color: #f5f8fa;
}


.el-textarea__inner{
  padding: 12px 10px;
  font-size: 12.5px;
  color: #4b4b4b;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(137, 152, 168, 0.2);
  }
}
.textarea-with-copy{
  position: relative;
  .el-textarea__inner{
    padding: 20px 12px 10px 12px;
  }
  .copy{
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
    i{
      color: #485763;
      font-size: 16px;
    }
  }
}
.zhi-input-text{
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  outline: none;
  padding: 6px 8px !important;
  resize: none;
  overflow-y: auto;
  color: #676767;
  font-size: 13px;
  line-height: 18px;
  &:focus{
    outline: none;
    border: 1px solid #1862ce;
  }
  &::placeholder {
    color: #aaaaaa;
  }
}