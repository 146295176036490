@charset "UTF-8";
:root {
  --bs-blue: #009ef6;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: #7E8299;
  --bs-gray-dark: #3F4254;
  --bs-gray-100: #F5F8FA;
  --bs-gray-200: #EFF2F5;
  --bs-gray-300: #E4E6EF;
  --bs-gray-400: #B5B5C3;
  --bs-gray-500: #A1A5B7;
  --bs-gray-600: #7E8299;
  --bs-gray-700: #5E6278;
  --bs-gray-800: #3F4254;
  --bs-gray-900: #181C32;
  --bs-white: #ffffff;
  --bs-light: #F5F8FA;
  --bs-primary: #009EF7;
  --bs-secondary: #E4E6EF;
  --bs-success: #50CD89;
  --bs-info: #7239EA;
  --bs-warning: #FFC700;
  --bs-danger: #F1416C;
  --bs-dark: #181C32;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 245, 248, 250;
  --bs-primary-rgb: 0, 158, 247;
  --bs-secondary-rgb: 228, 230, 239;
  --bs-success-rgb: 80, 205, 137;
  --bs-info-rgb: 114, 57, 234;
  --bs-warning-rgb: 255, 199, 0;
  --bs-danger-rgb: 241, 65, 108;
  --bs-dark-rgb: 24, 28, 50;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 24, 28, 50;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #181C32;
  --bs-body-bg: #ffffff;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px !important;
  font-weight: 400;
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 991.98px) {
  html,
  body {
    font-size: 13px !important;
  }
}
@media (max-width: 767.98px) {
  html,
  body {
    font-size: 12px !important;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #181C32;
}
h1, .h1 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.75rem;
  }
}
h2, .h2 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}
h3, .h3 {
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.35rem;
  }
}
h4, .h4 {
  font-size: 1.25rem;
}
h5, .h5 {
  font-size: 1.15rem;
}
h6, .h6 {
  font-size: 1.075rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: 600;
}
small, .small {
  font-size: 0.875em;
}
mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #009EF7;
  text-decoration: none;
}
a:hover {
  color: #006dab;
  text-decoration: underline;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: #b93993;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #181C32;
  border-radius: 0.275rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 500;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #A1A5B7;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role=button] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.flex-root {
  flex: 1;
}
.flex-column-auto {
  flex: none;
}
.flex-column-fluid {
  flex: 1 0 auto;
}
.flex-row-auto {
  flex: 0 0 auto;
}
.flex-row-fluid {
  flex: 1 auto;
  min-width: 0;
}

.w-auto {
  width: auto !important;
}
.w-1px {
  width: 1px !important;
}
.w-2px {
  width: 2px !important;
}
.w-3px {
  width: 3px !important;
}
.w-4px {
  width: 4px !important;
}
.w-5px {
  width: 5px !important;
}
.w-6px {
  width: 6px !important;
}
.w-7px {
  width: 7px !important;
}
.w-8px {
  width: 8px !important;
}
.w-9px {
  width: 9px !important;
}
.w-10px {
  width: 10px !important;
}
.w-15px {
  width: 15px !important;
}
.w-20px {
  width: 20px !important;
}
.w-25px {
  width: 25px !important;
}
.w-30px {
  width: 30px !important;
}
.w-35px {
  width: 35px !important;
}
.w-40px {
  width: 40px !important;
}
.w-45px {
  width: 45px !important;
}
.w-50px {
  width: 50px !important;
}
.w-55px {
  width: 55px !important;
}
.w-60px {
  width: 60px !important;
}
.w-65px {
  width: 65px !important;
}
.w-70px {
  width: 70px !important;
}
.w-75px {
  width: 75px !important;
}
.w-80px {
  width: 80px !important;
}
.w-85px {
  width: 85px !important;
}
.w-90px {
  width: 90px !important;
}
.w-95px {
  width: 95px !important;
}
.w-100px {
  width: 100px !important;
}
.w-125px {
  width: 125px !important;
}
.w-150px {
  width: 150px !important;
}
.w-175px {
  width: 175px !important;
}
.w-200px {
  width: 200px !important;
}
.w-225px {
  width: 225px !important;
}
.w-250px {
  width: 250px !important;
}
.w-275px {
  width: 275px !important;
}
.w-300px {
  width: 300px !important;
}
.w-325px {
  width: 325px !important;
}
.w-350px {
  width: 350px !important;
}
.w-375px {
  width: 375px !important;
}
.w-400px {
  width: 400px !important;
}
.w-425px {
  width: 425px !important;
}
.w-450px {
  width: 450px !important;
}
.w-475px {
  width: 475px !important;
}
.w-500px {
  width: 500px !important;
}
.w-550px {
  width: 550px !important;
}
.w-600px {
  width: 600px !important;
}
.w-650px {
  width: 650px !important;
}
.w-700px {
  width: 700px !important;
}
.w-750px {
  width: 750px !important;
}
.w-800px {
  width: 800px !important;
}
.w-850px {
  width: 850px !important;
}
.w-900px {
  width: 900px !important;
}
.w-950px {
  width: 950px !important;
}
.w-1000px {
  width: 1000px !important;
}
.mw-unset {
  max-width: unset !important;
}
.mw-25 {
  max-width: 25% !important;
}
.mw-50 {
  max-width: 50% !important;
}
.mw-75 {
  max-width: 75% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mw-auto {
  max-width: auto !important;
}
.mw-1px {
  max-width: 1px !important;
}
.mw-2px {
  max-width: 2px !important;
}
.mw-3px {
  max-width: 3px !important;
}
.mw-4px {
  max-width: 4px !important;
}
.mw-5px {
  max-width: 5px !important;
}
.mw-6px {
  max-width: 6px !important;
}
.mw-7px {
  max-width: 7px !important;
}
.mw-8px {
  max-width: 8px !important;
}
.mw-9px {
  max-width: 9px !important;
}
.mw-10px {
  max-width: 10px !important;
}
.mw-15px {
  max-width: 15px !important;
}
.mw-20px {
  max-width: 20px !important;
}
.mw-25px {
  max-width: 25px !important;
}
.mw-30px {
  max-width: 30px !important;
}
.mw-35px {
  max-width: 35px !important;
}
.mw-40px {
  max-width: 40px !important;
}
.mw-45px {
  max-width: 45px !important;
}
.mw-50px {
  max-width: 50px !important;
}
.mw-55px {
  max-width: 55px !important;
}
.mw-60px {
  max-width: 60px !important;
}
.mw-65px {
  max-width: 65px !important;
}
.mw-70px {
  max-width: 70px !important;
}
.mw-75px {
  max-width: 75px !important;
}
.mw-80px {
  max-width: 80px !important;
}
.mw-85px {
  max-width: 85px !important;
}
.mw-90px {
  max-width: 90px !important;
}
.mw-95px {
  max-width: 95px !important;
}
.mw-100px {
  max-width: 100px !important;
}
.mw-125px {
  max-width: 125px !important;
}
.mw-150px {
  max-width: 150px !important;
}
.mw-175px {
  max-width: 175px !important;
}
.mw-200px {
  max-width: 200px !important;
}
.mw-225px {
  max-width: 225px !important;
}
.mw-250px {
  max-width: 250px !important;
}
.mw-275px {
  max-width: 275px !important;
}
.mw-300px {
  max-width: 300px !important;
}
.mw-325px {
  max-width: 325px !important;
}
.mw-350px {
  max-width: 350px !important;
}
.mw-375px {
  max-width: 375px !important;
}
.mw-400px {
  max-width: 400px !important;
}
.mw-425px {
  max-width: 425px !important;
}
.mw-450px {
  max-width: 450px !important;
}
.mw-475px {
  max-width: 475px !important;
}
.mw-500px {
  max-width: 500px !important;
}
.mw-550px {
  max-width: 550px !important;
}
.mw-600px {
  max-width: 600px !important;
}
.mw-650px {
  max-width: 650px !important;
}
.mw-700px {
  max-width: 700px !important;
}
.mw-750px {
  max-width: 750px !important;
}
.mw-800px {
  max-width: 800px !important;
}
.mw-850px {
  max-width: 850px !important;
}
.mw-900px {
  max-width: 900px !important;
}
.mw-950px {
  max-width: 950px !important;
}
.mw-1000px {
  max-width: 1000px !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-unset {
  height: unset !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.h-1px {
  height: 1px !important;
}
.h-2px {
  height: 2px !important;
}
.h-3px {
  height: 3px !important;
}
.h-4px {
  height: 4px !important;
}
.h-5px {
  height: 5px !important;
}
.h-6px {
  height: 6px !important;
}
.h-7px {
  height: 7px !important;
}
.h-8px {
  height: 8px !important;
}
.h-9px {
  height: 9px !important;
}
.h-10px {
  height: 10px !important;
}
.h-15px {
  height: 15px !important;
}
.h-20px {
  height: 20px !important;
}
.h-25px {
  height: 25px !important;
}
.h-30px {
  height: 30px !important;
}
.h-35px {
  height: 35px !important;
}
.h-40px {
  height: 40px !important;
}
.h-45px {
  height: 45px !important;
}
.h-50px {
  height: 50px !important;
}
.h-55px {
  height: 55px !important;
}
.h-60px {
  height: 60px !important;
}
.h-65px {
  height: 65px !important;
}
.h-70px {
  height: 70px !important;
}
.h-75px {
  height: 75px !important;
}
.h-80px {
  height: 80px !important;
}
.h-85px {
  height: 85px !important;
}
.h-90px {
  height: 90px !important;
}
.h-95px {
  height: 95px !important;
}
.h-100px {
  height: 100px !important;
}
.h-125px {
  height: 125px !important;
}
.h-150px {
  height: 150px !important;
}
.h-175px {
  height: 175px !important;
}
.h-200px {
  height: 200px !important;
}
.h-225px {
  height: 225px !important;
}
.h-250px {
  height: 250px !important;
}
.h-275px {
  height: 275px !important;
}
.h-300px {
  height: 300px !important;
}
.h-325px {
  height: 325px !important;
}
.h-350px {
  height: 350px !important;
}
.h-375px {
  height: 375px !important;
}
.h-400px {
  height: 400px !important;
}
.h-425px {
  height: 425px !important;
}
.h-450px {
  height: 450px !important;
}
.h-475px {
  height: 475px !important;
}
.h-500px {
  height: 500px !important;
}
.h-550px {
  height: 550px !important;
}
.h-600px {
  height: 600px !important;
}
.h-650px {
  height: 650px !important;
}
.h-700px {
  height: 700px !important;
}
.h-750px {
  height: 750px !important;
}
.h-800px {
  height: 800px !important;
}
.h-850px {
  height: 850px !important;
}
.h-900px {
  height: 900px !important;
}
.h-950px {
  height: 950px !important;
}
.h-1000px {
  height: 1000px !important;
}
.mh-unset {
  max-height: unset !important;
}
.mh-25 {
  max-height: 25% !important;
}
.mh-50 {
  max-height: 50% !important;
}
.mh-75 {
  max-height: 75% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.mh-auto {
  max-height: auto !important;
}
.mh-1px {
  max-height: 1px !important;
}
.mh-2px {
  max-height: 2px !important;
}
.mh-3px {
  max-height: 3px !important;
}
.mh-4px {
  max-height: 4px !important;
}
.mh-5px {
  max-height: 5px !important;
}
.mh-6px {
  max-height: 6px !important;
}
.mh-7px {
  max-height: 7px !important;
}
.mh-8px {
  max-height: 8px !important;
}
.mh-9px {
  max-height: 9px !important;
}
.mh-10px {
  max-height: 10px !important;
}
.mh-15px {
  max-height: 15px !important;
}
.mh-20px {
  max-height: 20px !important;
}
.mh-25px {
  max-height: 25px !important;
}
.mh-30px {
  max-height: 30px !important;
}
.mh-35px {
  max-height: 35px !important;
}
.mh-40px {
  max-height: 40px !important;
}
.mh-45px {
  max-height: 45px !important;
}
.mh-50px {
  max-height: 50px !important;
}
.mh-55px {
  max-height: 55px !important;
}
.mh-60px {
  max-height: 60px !important;
}
.mh-65px {
  max-height: 65px !important;
}
.mh-70px {
  max-height: 70px !important;
}
.mh-75px {
  max-height: 75px !important;
}
.mh-80px {
  max-height: 80px !important;
}
.mh-85px {
  max-height: 85px !important;
}
.mh-90px {
  max-height: 90px !important;
}
.mh-95px {
  max-height: 95px !important;
}
.mh-100px {
  max-height: 100px !important;
}
.mh-125px {
  max-height: 125px !important;
}
.mh-150px {
  max-height: 150px !important;
}
.mh-175px {
  max-height: 175px !important;
}
.mh-200px {
  max-height: 200px !important;
}
.mh-225px {
  max-height: 225px !important;
}
.mh-250px {
  max-height: 250px !important;
}
.mh-275px {
  max-height: 275px !important;
}
.mh-300px {
  max-height: 300px !important;
}
.mh-325px {
  max-height: 325px !important;
}
.mh-350px {
  max-height: 350px !important;
}
.mh-375px {
  max-height: 375px !important;
}
.mh-400px {
  max-height: 400px !important;
}
.mh-425px {
  max-height: 425px !important;
}
.mh-450px {
  max-height: 450px !important;
}
.mh-475px {
  max-height: 475px !important;
}
.mh-500px {
  max-height: 500px !important;
}
.mh-550px {
  max-height: 550px !important;
}
.mh-600px {
  max-height: 600px !important;
}
.mh-650px {
  max-height: 650px !important;
}
.mh-700px {
  max-height: 700px !important;
}
.mh-750px {
  max-height: 750px !important;
}
.mh-800px {
  max-height: 800px !important;
}
.mh-850px {
  max-height: 850px !important;
}
.mh-900px {
  max-height: 900px !important;
}
.mh-950px {
  max-height: 950px !important;
}
.mh-1000px {
  max-height: 1000px !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.min-w-unset {
  min-width: unset !important;
}
.min-w-25 {
  min-width: 25% !important;
}
.min-w-50 {
  min-width: 50% !important;
}
.min-w-75 {
  min-width: 75% !important;
}
.min-w-100 {
  min-width: 100% !important;
}
.min-w-auto {
  min-width: auto !important;
}
.min-w-1px {
  min-width: 1px !important;
}
.min-w-2px {
  min-width: 2px !important;
}
.min-w-3px {
  min-width: 3px !important;
}
.min-w-4px {
  min-width: 4px !important;
}
.min-w-5px {
  min-width: 5px !important;
}
.min-w-6px {
  min-width: 6px !important;
}
.min-w-7px {
  min-width: 7px !important;
}
.min-w-8px {
  min-width: 8px !important;
}
.min-w-9px {
  min-width: 9px !important;
}
.min-w-10px {
  min-width: 10px !important;
}
.min-w-15px {
  min-width: 15px !important;
}
.min-w-20px {
  min-width: 20px !important;
}
.min-w-25px {
  min-width: 25px !important;
}
.min-w-30px {
  min-width: 30px !important;
}
.min-w-35px {
  min-width: 35px !important;
}
.min-w-40px {
  min-width: 40px !important;
}
.min-w-45px {
  min-width: 45px !important;
}
.min-w-50px {
  min-width: 50px !important;
}
.min-w-55px {
  min-width: 55px !important;
}
.min-w-60px {
  min-width: 60px !important;
}
.min-w-65px {
  min-width: 65px !important;
}
.min-w-70px {
  min-width: 70px !important;
}
.min-w-75px {
  min-width: 75px !important;
}
.min-w-80px {
  min-width: 80px !important;
}
.min-w-85px {
  min-width: 85px !important;
}
.min-w-90px {
  min-width: 90px !important;
}
.min-w-95px {
  min-width: 95px !important;
}
.min-w-100px {
  min-width: 100px !important;
}
.min-w-125px {
  min-width: 125px !important;
}
.min-w-150px {
  min-width: 150px !important;
}
.min-w-175px {
  min-width: 175px !important;
}
.min-w-200px {
  min-width: 200px !important;
}
.min-w-225px {
  min-width: 225px !important;
}
.min-w-250px {
  min-width: 250px !important;
}
.min-w-275px {
  min-width: 275px !important;
}
.min-w-300px {
  min-width: 300px !important;
}
.min-w-325px {
  min-width: 325px !important;
}
.min-w-350px {
  min-width: 350px !important;
}
.min-w-375px {
  min-width: 375px !important;
}
.min-w-400px {
  min-width: 400px !important;
}
.min-w-425px {
  min-width: 425px !important;
}
.min-w-450px {
  min-width: 450px !important;
}
.min-w-475px {
  min-width: 475px !important;
}
.min-w-500px {
  min-width: 500px !important;
}
.min-w-550px {
  min-width: 550px !important;
}
.min-w-600px {
  min-width: 600px !important;
}
.min-w-650px {
  min-width: 650px !important;
}
.min-w-700px {
  min-width: 700px !important;
}
.min-w-750px {
  min-width: 750px !important;
}
.min-w-800px {
  min-width: 800px !important;
}
.min-w-850px {
  min-width: 850px !important;
}
.min-w-900px {
  min-width: 900px !important;
}
.min-w-950px {
  min-width: 950px !important;
}
.min-w-1000px {
  min-width: 1000px !important;
}
.min-h-unset {
  min-height: unset !important;
}
.min-h-25 {
  min-height: 25% !important;
}
.min-h-50 {
  min-height: 50% !important;
}
.min-h-75 {
  min-height: 75% !important;
}
.min-h-100 {
  min-height: 100% !important;
}
.min-h-auto {
  min-height: auto !important;
}
.min-h-1px {
  min-height: 1px !important;
}
.min-h-2px {
  min-height: 2px !important;
}
.min-h-3px {
  min-height: 3px !important;
}
.min-h-4px {
  min-height: 4px !important;
}
.min-h-5px {
  min-height: 5px !important;
}
.min-h-6px {
  min-height: 6px !important;
}
.min-h-7px {
  min-height: 7px !important;
}
.min-h-8px {
  min-height: 8px !important;
}
.min-h-9px {
  min-height: 9px !important;
}
.min-h-10px {
  min-height: 10px !important;
}
.min-h-15px {
  min-height: 15px !important;
}
.min-h-20px {
  min-height: 20px !important;
}
.min-h-25px {
  min-height: 25px !important;
}
.min-h-30px {
  min-height: 30px !important;
}
.min-h-35px {
  min-height: 35px !important;
}
.min-h-40px {
  min-height: 40px !important;
}
.min-h-45px {
  min-height: 45px !important;
}
.min-h-50px {
  min-height: 50px !important;
}
.min-h-55px {
  min-height: 55px !important;
}
.min-h-60px {
  min-height: 60px !important;
}
.min-h-65px {
  min-height: 65px !important;
}
.min-h-70px {
  min-height: 70px !important;
}
.min-h-75px {
  min-height: 75px !important;
}
.min-h-80px {
  min-height: 80px !important;
}
.min-h-85px {
  min-height: 85px !important;
}
.min-h-90px {
  min-height: 90px !important;
}
.min-h-95px {
  min-height: 95px !important;
}
.min-h-100px {
  min-height: 100px !important;
}
.min-h-125px {
  min-height: 125px !important;
}
.min-h-150px {
  min-height: 150px !important;
}
.min-h-175px {
  min-height: 175px !important;
}
.min-h-200px {
  min-height: 200px !important;
}
.min-h-225px {
  min-height: 225px !important;
}
.min-h-250px {
  min-height: 250px !important;
}
.min-h-275px {
  min-height: 275px !important;
}
.min-h-300px {
  min-height: 300px !important;
}
.min-h-325px {
  min-height: 325px !important;
}
.min-h-350px {
  min-height: 350px !important;
}
.min-h-375px {
  min-height: 375px !important;
}
.min-h-400px {
  min-height: 400px !important;
}
.min-h-425px {
  min-height: 425px !important;
}
.min-h-450px {
  min-height: 450px !important;
}
.min-h-475px {
  min-height: 475px !important;
}
.min-h-500px {
  min-height: 500px !important;
}
.min-h-550px {
  min-height: 550px !important;
}
.min-h-600px {
  min-height: 600px !important;
}
.min-h-650px {
  min-height: 650px !important;
}
.min-h-700px {
  min-height: 700px !important;
}
.min-h-750px {
  min-height: 750px !important;
}
.min-h-800px {
  min-height: 800px !important;
}
.min-h-850px {
  min-height: 850px !important;
}
.min-h-900px {
  min-height: 900px !important;
}
.min-h-950px {
  min-height: 950px !important;
}
.min-h-1000px {
  min-height: 1000px !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 0.75rem !important;
}
.gap-4 {
  gap: 1rem !important;
}
.gap-5 {
  gap: 1.25rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.gap-7 {
  gap: 1.75rem !important;
}
.gap-8 {
  gap: 2rem !important;
}
.gap-9 {
  gap: 2.25rem !important;
}
.gap-10 {
  gap: 2.5rem !important;
}
.gap-11 {
  gap: 2.75rem !important;
}
.gap-12 {
  gap: 3rem !important;
}
.gap-13 {
  gap: 3.25rem !important;
}
.gap-14 {
  gap: 3.5rem !important;
}
.gap-15 {
  gap: 3.75rem !important;
}
.gap-16 {
  gap: 4rem !important;
}
.gap-17 {
  gap: 4.25rem !important;
}
.gap-18 {
  gap: 4.5rem !important;
}
.gap-19 {
  gap: 4.75rem !important;
}
.gap-20 {
  gap: 5rem !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 0.75rem !important;
}
.m-4 {
  margin: 1rem !important;
}
.m-5 {
  margin: 1.25rem !important;
}
.m-6 {
  margin: 1.5rem !important;
}
.m-7 {
  margin: 1.75rem !important;
}
.m-8 {
  margin: 2rem !important;
}
.m-9 {
  margin: 2.25rem !important;
}
.m-10 {
  margin: 2.5rem !important;
}
.m-11 {
  margin: 2.75rem !important;
}
.m-12 {
  margin: 3rem !important;
}
.m-13 {
  margin: 3.25rem !important;
}
.m-14 {
  margin: 3.5rem !important;
}
.m-15 {
  margin: 3.75rem !important;
}
.m-16 {
  margin: 4rem !important;
}
.m-17 {
  margin: 4.25rem !important;
}
.m-18 {
  margin: 4.5rem !important;
}
.m-19 {
  margin: 4.75rem !important;
}
.m-20 {
  margin: 5rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}
.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}
.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}
.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}
.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}
.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}
.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}
.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}
.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}
.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}
.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}
.mx-17 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}
.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}
.mx-19 {
  margin-right: 4.75rem !important;
  margin-left: 4.75rem !important;
}
.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}
.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}
.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}
.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}
.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}
.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}
.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}
.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}
.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}
.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-15px {
  margin-top: 15px !important;
}
.mt-20px {
  margin-top: 20px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mt-5 {
  margin-top: 1.25rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-7 {
  margin-top: 1.75rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.mt-9 {
  margin-top: 2.25rem !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}
.mt-11 {
  margin-top: 2.75rem !important;
}
.mt-12 {
  margin-top: 3rem !important;
}
.mt-13 {
  margin-top: 3.25rem !important;
}
.mt-14 {
  margin-top: 3.5rem !important;
}
.mt-15 {
  margin-top: 3.75rem !important;
}
.mt-16 {
  margin-top: 4rem !important;
}
.mt-17 {
  margin-top: 4.25rem !important;
}
.mt-18 {
  margin-top: 4.5rem !important;
}
.mt-19 {
  margin-top: 4.75rem !important;
}
.mt-20 {
  margin-top: 5rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 0.75rem !important;
}
.me-4 {
  margin-right: 1rem !important;
}
.me-5 {
  margin-right: 1.25rem !important;
}
.me-6 {
  margin-right: 1.5rem !important;
}
.me-7 {
  margin-right: 1.75rem !important;
}
.me-8 {
  margin-right: 2rem !important;
}
.me-9 {
  margin-right: 2.25rem !important;
}
.me-10 {
  margin-right: 2.5rem !important;
}
.me-11 {
  margin-right: 2.75rem !important;
}
.me-12 {
  margin-right: 3rem !important;
}
.me-13 {
  margin-right: 3.25rem !important;
}
.me-14 {
  margin-right: 3.5rem !important;
}
.me-15 {
  margin-right: 3.75rem !important;
}
.me-16 {
  margin-right: 4rem !important;
}
.me-17 {
  margin-right: 4.25rem !important;
}
.me-18 {
  margin-right: 4.5rem !important;
}
.me-19 {
  margin-right: 4.75rem !important;
}
.me-20 {
  margin-right: 5rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mb-7 {
  margin-bottom: 1.75rem !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.mb-9 {
  margin-bottom: 2.25rem !important;
}
.mb-10 {
  margin-bottom: 2.5rem !important;
}
.mb-11 {
  margin-bottom: 2.75rem !important;
}
.mb-12 {
  margin-bottom: 3rem !important;
}
.mb-13 {
  margin-bottom: 3.25rem !important;
}
.mb-14 {
  margin-bottom: 3.5rem !important;
}
.mb-15 {
  margin-bottom: 3.75rem !important;
}
.mb-16 {
  margin-bottom: 4rem !important;
}
.mb-17 {
  margin-bottom: 4.25rem !important;
}
.mb-18 {
  margin-bottom: 4.5rem !important;
}
.mb-19 {
  margin-bottom: 4.75rem !important;
}
.mb-20 {
  margin-bottom: 5rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 0.75rem !important;
}
.ms-4 {
  margin-left: 1rem !important;
}
.ms-5 {
  margin-left: 1.25rem !important;
}
.ms-6 {
  margin-left: 1.5rem !important;
}
.ms-7 {
  margin-left: 1.75rem !important;
}
.ms-8 {
  margin-left: 2rem !important;
}
.ms-9 {
  margin-left: 2.25rem !important;
}
.ms-10 {
  margin-left: 2.5rem !important;
}
.ms-11 {
  margin-left: 2.75rem !important;
}
.ms-12 {
  margin-left: 3rem !important;
}
.ms-13 {
  margin-left: 3.25rem !important;
}
.ms-14 {
  margin-left: 3.5rem !important;
}
.ms-15 {
  margin-left: 3.75rem !important;
}
.ms-16 {
  margin-left: 4rem !important;
}
.ms-17 {
  margin-left: 4.25rem !important;
}
.ms-18 {
  margin-left: 4.5rem !important;
}
.ms-19 {
  margin-left: 4.75rem !important;
}
.ms-20 {
  margin-left: 5rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.m-n3 {
  margin: -0.75rem !important;
}
.m-n4 {
  margin: -1rem !important;
}
.m-n5 {
  margin: -1.25rem !important;
}
.m-n6 {
  margin: -1.5rem !important;
}
.m-n7 {
  margin: -1.75rem !important;
}
.m-n8 {
  margin: -2rem !important;
}
.m-n9 {
  margin: -2.25rem !important;
}
.m-n10 {
  margin: -2.5rem !important;
}
.m-n11 {
  margin: -2.75rem !important;
}
.m-n12 {
  margin: -3rem !important;
}
.m-n13 {
  margin: -3.25rem !important;
}
.m-n14 {
  margin: -3.5rem !important;
}
.m-n15 {
  margin: -3.75rem !important;
}
.m-n16 {
  margin: -4rem !important;
}
.m-n17 {
  margin: -4.25rem !important;
}
.m-n18 {
  margin: -4.5rem !important;
}
.m-n19 {
  margin: -4.75rem !important;
}
.m-n20 {
  margin: -5rem !important;
}
.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}
.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}
.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}
.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}
.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}
.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}
.mx-n11 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}
.mx-n12 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}
.mx-n13 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}
.mx-n14 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}
.mx-n15 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}
.mx-n16 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}
.mx-n17 {
  margin-right: -4.25rem !important;
  margin-left: -4.25rem !important;
}
.mx-n18 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}
.mx-n19 {
  margin-right: -4.75rem !important;
  margin-left: -4.75rem !important;
}
.mx-n20 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}
.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}
.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}
.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}
.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}
.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}
.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}
.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}
.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}
.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}
.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}
.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}
.my-n17 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}
.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}
.my-n19 {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}
.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.mt-n2 {
  margin-top: -0.5rem !important;
}
.mt-n3 {
  margin-top: -0.75rem !important;
}
.mt-n4 {
  margin-top: -1rem !important;
}
.mt-n5 {
  margin-top: -1.25rem !important;
}
.mt-n6 {
  margin-top: -1.5rem !important;
}
.mt-n7 {
  margin-top: -1.75rem !important;
}
.mt-n8 {
  margin-top: -2rem !important;
}
.mt-n9 {
  margin-top: -2.25rem !important;
}
.mt-n10 {
  margin-top: -2.5rem !important;
}
.mt-n11 {
  margin-top: -2.75rem !important;
}
.mt-n12 {
  margin-top: -3rem !important;
}
.mt-n13 {
  margin-top: -3.25rem !important;
}
.mt-n14 {
  margin-top: -3.5rem !important;
}
.mt-n15 {
  margin-top: -3.75rem !important;
}
.mt-n16 {
  margin-top: -4rem !important;
}
.mt-n17 {
  margin-top: -4.25rem !important;
}
.mt-n18 {
  margin-top: -4.5rem !important;
}
.mt-n19 {
  margin-top: -4.75rem !important;
}
.mt-n20 {
  margin-top: -5rem !important;
}
.me-n1 {
  margin-right: -0.25rem !important;
}
.me-n2 {
  margin-right: -0.5rem !important;
}
.me-n3 {
  margin-right: -0.75rem !important;
}
.me-n4 {
  margin-right: -1rem !important;
}
.me-n5 {
  margin-right: -1.25rem !important;
}
.me-n6 {
  margin-right: -1.5rem !important;
}
.me-n7 {
  margin-right: -1.75rem !important;
}
.me-n8 {
  margin-right: -2rem !important;
}
.me-n9 {
  margin-right: -2.25rem !important;
}
.me-n10 {
  margin-right: -2.5rem !important;
}
.me-n11 {
  margin-right: -2.75rem !important;
}
.me-n12 {
  margin-right: -3rem !important;
}
.me-n13 {
  margin-right: -3.25rem !important;
}
.me-n14 {
  margin-right: -3.5rem !important;
}
.me-n15 {
  margin-right: -3.75rem !important;
}
.me-n16 {
  margin-right: -4rem !important;
}
.me-n17 {
  margin-right: -4.25rem !important;
}
.me-n18 {
  margin-right: -4.5rem !important;
}
.me-n19 {
  margin-right: -4.75rem !important;
}
.me-n20 {
  margin-right: -5rem !important;
}
.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.mb-n3 {
  margin-bottom: -0.75rem !important;
}
.mb-n4 {
  margin-bottom: -1rem !important;
}
.mb-n5 {
  margin-bottom: -1.25rem !important;
}
.mb-n6 {
  margin-bottom: -1.5rem !important;
}
.mb-n7 {
  margin-bottom: -1.75rem !important;
}
.mb-n8 {
  margin-bottom: -2rem !important;
}
.mb-n9 {
  margin-bottom: -2.25rem !important;
}
.mb-n10 {
  margin-bottom: -2.5rem !important;
}
.mb-n11 {
  margin-bottom: -2.75rem !important;
}
.mb-n12 {
  margin-bottom: -3rem !important;
}
.mb-n13 {
  margin-bottom: -3.25rem !important;
}
.mb-n14 {
  margin-bottom: -3.5rem !important;
}
.mb-n15 {
  margin-bottom: -3.75rem !important;
}
.mb-n16 {
  margin-bottom: -4rem !important;
}
.mb-n17 {
  margin-bottom: -4.25rem !important;
}
.mb-n18 {
  margin-bottom: -4.5rem !important;
}
.mb-n19 {
  margin-bottom: -4.75rem !important;
}
.mb-n20 {
  margin-bottom: -5rem !important;
}
.ms-n1 {
  margin-left: -0.25rem !important;
}
.ms-n2 {
  margin-left: -0.5rem !important;
}
.ms-n3 {
  margin-left: -0.75rem !important;
}
.ms-n4 {
  margin-left: -1rem !important;
}
.ms-n5 {
  margin-left: -1.25rem !important;
}
.ms-n6 {
  margin-left: -1.5rem !important;
}
.ms-n7 {
  margin-left: -1.75rem !important;
}
.ms-n8 {
  margin-left: -2rem !important;
}
.ms-n9 {
  margin-left: -2.25rem !important;
}
.ms-n10 {
  margin-left: -2.5rem !important;
}
.ms-n11 {
  margin-left: -2.75rem !important;
}
.ms-n12 {
  margin-left: -3rem !important;
}
.ms-n13 {
  margin-left: -3.25rem !important;
}
.ms-n14 {
  margin-left: -3.5rem !important;
}
.ms-n15 {
  margin-left: -3.75rem !important;
}
.ms-n16 {
  margin-left: -4rem !important;
}
.ms-n17 {
  margin-left: -4.25rem !important;
}
.ms-n18 {
  margin-left: -4.5rem !important;
}
.ms-n19 {
  margin-left: -4.75rem !important;
}
.ms-n20 {
  margin-left: -5rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-7 {
  padding: 1.75rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.p-9 {
  padding: 2.25rem !important;
}
.p-10 {
  padding: 2.5rem !important;
}
.p-11 {
  padding: 2.75rem !important;
}
.p-12 {
  padding: 3rem !important;
}
.p-13 {
  padding: 3.25rem !important;
}
.p-14 {
  padding: 3.5rem !important;
}
.p-15 {
  padding: 3.75rem !important;
}
.p-16 {
  padding: 4rem !important;
}
.p-17 {
  padding: 4.25rem !important;
}
.p-18 {
  padding: 4.5rem !important;
}
.p-19 {
  padding: 4.75rem !important;
}
.p-20 {
  padding: 5rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}
.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}
.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}
.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}
.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}
.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}
.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}
.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}
.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}
.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}
.px-17 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}
.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}
.px-19 {
  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;
}
.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}
.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}
.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}
.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}
.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}
.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}
.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}
.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}
.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}
.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pt-5 {
  padding-top: 1.25rem !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.pt-7 {
  padding-top: 1.75rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}
.pt-9 {
  padding-top: 2.25rem !important;
}
.pt-10 {
  padding-top: 2.5rem !important;
}
.pt-11 {
  padding-top: 2.75rem !important;
}
.pt-12 {
  padding-top: 3rem !important;
}
.pt-13 {
  padding-top: 3.25rem !important;
}
.pt-14 {
  padding-top: 3.5rem !important;
}
.pt-15 {
  padding-top: 3.75rem !important;
}
.pt-16 {
  padding-top: 4rem !important;
}
.pt-17 {
  padding-top: 4.25rem !important;
}
.pt-18 {
  padding-top: 4.5rem !important;
}
.pt-19 {
  padding-top: 4.75rem !important;
}
.pt-20 {
  padding-top: 5rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 0.75rem !important;
}
.pe-4 {
  padding-right: 1rem !important;
}
.pe-5 {
  padding-right: 1.25rem !important;
}
.pe-6 {
  padding-right: 1.5rem !important;
}
.pe-7 {
  padding-right: 1.75rem !important;
}
.pe-8 {
  padding-right: 2rem !important;
}
.pe-9 {
  padding-right: 2.25rem !important;
}
.pe-10 {
  padding-right: 2.5rem !important;
}
.pe-11 {
  padding-right: 2.75rem !important;
}
.pe-12 {
  padding-right: 3rem !important;
}
.pe-13 {
  padding-right: 3.25rem !important;
}
.pe-14 {
  padding-right: 3.5rem !important;
}
.pe-15 {
  padding-right: 3.75rem !important;
}
.pe-16 {
  padding-right: 4rem !important;
}
.pe-17 {
  padding-right: 4.25rem !important;
}
.pe-18 {
  padding-right: 4.5rem !important;
}
.pe-19 {
  padding-right: 4.75rem !important;
}
.pe-20 {
  padding-right: 5rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-5 {
  padding-bottom: 1.25rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pb-7 {
  padding-bottom: 1.75rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.pb-9 {
  padding-bottom: 2.25rem !important;
}
.pb-10 {
  padding-bottom: 2.5rem !important;
}
.pb-11 {
  padding-bottom: 2.75rem !important;
}
.pb-12 {
  padding-bottom: 3rem !important;
}
.pb-13 {
  padding-bottom: 3.25rem !important;
}
.pb-14 {
  padding-bottom: 3.5rem !important;
}
.pb-15 {
  padding-bottom: 3.75rem !important;
}
.pb-16 {
  padding-bottom: 4rem !important;
}
.pb-17 {
  padding-bottom: 4.25rem !important;
}
.pb-18 {
  padding-bottom: 4.5rem !important;
}
.pb-19 {
  padding-bottom: 4.75rem !important;
}
.pb-20 {
  padding-bottom: 5rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 0.75rem !important;
}
.ps-4 {
  padding-left: 1rem !important;
}
.ps-5 {
  padding-left: 1.25rem !important;
}
.ps-6 {
  padding-left: 1.5rem !important;
}
.ps-7 {
  padding-left: 1.75rem !important;
}
.ps-8 {
  padding-left: 2rem !important;
}
.ps-9 {
  padding-left: 2.25rem !important;
}
.ps-10 {
  padding-left: 2.5rem !important;
}
.ps-11 {
  padding-left: 2.75rem !important;
}
.ps-12 {
  padding-left: 3rem !important;
}
.ps-13 {
  padding-left: 3.25rem !important;
}
.ps-14 {
  padding-left: 3.5rem !important;
}
.ps-15 {
  padding-left: 3.75rem !important;
}
.ps-16 {
  padding-left: 4rem !important;
}
.ps-17 {
  padding-left: 4.25rem !important;
}
.ps-18 {
  padding-left: 4.5rem !important;
}
.ps-19 {
  padding-left: 4.75rem !important;
}
.ps-20 {
  padding-left: 5rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}
.fs-4 {
  font-size: 1.25rem !important;
}
.fs-5 {
  font-size: 1.15rem !important;
}
.fs-6 {
  font-size: 1.075rem !important;
}
.fs-7 {
  font-size: 0.95rem !important;
}
.fs-8 {
  font-size: 0.85rem !important;
}
.fs-9 {
  font-size: 0.75rem !important;
}
.fs-10 {
  font-size: 0.5rem !important;
}
.fs-base {
  font-size: 1rem !important;
}
.fs-fluid {
  font-size: 100% !important;
}
.fs-2x {
  font-size: calc(1.325rem + 0.9vw) !important;
}
.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}
.fs-2hx {
  font-size: calc(1.375rem + 1.5vw) !important;
}
.fs-2tx {
  font-size: calc(1.4rem + 1.8vw) !important;
}
.fs-3x {
  font-size: calc(1.425rem + 2.1vw) !important;
}
.fs-3qx {
  font-size: calc(1.45rem + 2.4vw) !important;
}
.fs-3hx {
  font-size: calc(1.475rem + 2.7vw) !important;
}
.fs-3tx {
  font-size: calc(1.5rem + 3vw) !important;
}
.fs-4x {
  font-size: calc(1.525rem + 3.3vw) !important;
}
.fs-4qx {
  font-size: calc(1.55rem + 3.6vw) !important;
}
.fs-4hx {
  font-size: calc(1.575rem + 3.9vw) !important;
}
.fs-4tx {
  font-size: calc(1.6rem + 4.2vw) !important;
}
.fs-5x {
  font-size: calc(1.625rem + 4.5vw) !important;
}
.fs-5qx {
  font-size: calc(1.65rem + 4.8vw) !important;
}
.fs-5hx {
  font-size: calc(1.675rem + 5.1vw) !important;
}
.fs-5tx {
  font-size: calc(1.7rem + 5.4vw) !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 500 !important;
}
.fw-bolder {
  font-weight: 600 !important;
}
.lh-0 {
  line-height: 0 !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 1.75 !important;
}
.lh-xl {
  line-height: 2 !important;
}
.lh-xxl {
  line-height: 2.25 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #A1A5B7 !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.rounded {
  border-radius: 0.475rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 0.275rem !important;
}
.rounded-2 {
  border-radius: 0.475rem !important;
}
.rounded-3 {
  border-radius: 0.775rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-top {
  border-top-left-radius: 0.475rem !important;
  border-top-right-radius: 0.475rem !important;
}
.rounded-end {
  border-top-right-radius: 0.475rem !important;
  border-bottom-right-radius: 0.475rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.475rem !important;
  border-bottom-left-radius: 0.475rem !important;
}
.rounded-start {
  border-bottom-left-radius: 0.475rem !important;
  border-top-left-radius: 0.475rem !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-0-hover:hover {
  opacity: 0 !important;
}
.opacity-5 {
  opacity: 0.05 !important;
}
.opacity-5-hover:hover {
  opacity: 0.05 !important;
}
.opacity-10 {
  opacity: 0.1 !important;
}
.opacity-10-hover:hover {
  opacity: 0.1 !important;
}
.opacity-15 {
  opacity: 0.15 !important;
}
.opacity-15-hover:hover {
  opacity: 0.15 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-20-hover:hover {
  opacity: 0.2 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-25-hover:hover {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-50-hover:hover {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-75-hover:hover {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.opacity-100-hover:hover {
  opacity: 1 !important;
}
.text-gray-100 {
  color: #F5F8FA !important;
}
.text-hover-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-100:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-hover-gray-100:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-hover-gray-100:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.text-hover-gray-100:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-100.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-active-gray-100.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F5F8FA !important;
}
.text-active-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.text-active-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-200 {
  color: #EFF2F5 !important;
}
.text-hover-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-200:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-hover-gray-200:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-hover-gray-200:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.text-hover-gray-200:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-200.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-active-gray-200.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #EFF2F5 !important;
}
.text-active-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.text-active-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-300 {
  color: #E4E6EF !important;
}
.text-hover-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-300:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-hover-gray-300:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-hover-gray-300:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.text-hover-gray-300:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-300.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-active-gray-300.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #E4E6EF !important;
}
.text-active-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.text-active-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-400 {
  color: #B5B5C3 !important;
}
.text-hover-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-400:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-hover-gray-400:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-hover-gray-400:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.text-hover-gray-400:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-400.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-active-gray-400.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #B5B5C3 !important;
}
.text-active-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.text-active-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-500 {
  color: #A1A5B7 !important;
}
.text-hover-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-500:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-hover-gray-500:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-hover-gray-500:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.text-hover-gray-500:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-500.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-active-gray-500.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #A1A5B7 !important;
}
.text-active-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.text-active-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-600 {
  color: #7E8299 !important;
}
.text-hover-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-600:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-gray-600:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-hover-gray-600:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-hover-gray-600:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-600.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-gray-600.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7E8299 !important;
}
.text-active-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.text-active-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-700 {
  color: #5E6278 !important;
}
.text-hover-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-700:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-hover-gray-700:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-hover-gray-700:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.text-hover-gray-700:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-700.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-active-gray-700.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5E6278 !important;
}
.text-active-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.text-active-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-800 {
  color: #3F4254 !important;
}
.text-hover-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-800:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-hover-gray-800:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-hover-gray-800:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.text-hover-gray-800:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-800.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-active-gray-800.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3F4254 !important;
}
.text-active-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.text-active-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-gray-900 {
  color: #181C32 !important;
}
.text-hover-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-900:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-hover-gray-900:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-hover-gray-900:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.text-hover-gray-900:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-900.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-active-gray-900.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181C32 !important;
}
.text-active-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.text-active-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}